<template>
  <vx-card title="DN With and Without PO Reference">
    <div class="flex flex-col gap-5">
      <div class="vx-col sm:w-2/3 w-full" v-if="currentTab <= 1">
        <div class="flex flex-col gap-1 w-full">
          <span>Supplier Code</span>
          <multiselect
            class="selectExample"
            v-model="selectedSupplierCodes"
            :options="suplierCodeOptions.length === 0 ? [] : suplierCodeOptions"
            :multiple="true"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="3"
            placeholder="Type to search"
            track-by="id"
            :disabled="false"
            @search-change="onSupplierCodeSearch"
            @input="onSupplierCodeChange"
            :custom-label="queryPrincipal"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span
                  v-if="props.option.supplier_code !== 'All'"
                  class="option__title"
                >
                  {{ props.option.supplier_code }} -
                  {{ props.option.supplier_name }}</span
                >
                <span v-else class="option__title">
                  {{ props.option.supplier_code }}</span
                >
              </div>
            </template>
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span
                  v-if="props.option.supplier_code !== 'All'"
                  class="option__title"
                >
                  {{ props.option.supplier_code }} -
                  {{ props.option.supplier_name }}</span
                >
                <span v-else class="option__title">
                  {{ props.option.supplier_code }}</span
                >
                <i
                  class="multiselect__tag-icon"
                  @click.prevent
                  @mousedown.prevent.stop="props.remove(props.option, $event)"
                />
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-col sm:w-2/3 w-full" v-if="currentTab <= 1">
        <div class="flex flex-col gap-1 w-full">
          <span>SR Number</span>
          <multiselect
            class="selectExample"
            v-model="selectedSRNumbers"
            :options="SrNumberOptions.length === 0 ? [] : SrNumberOptions"
            :multiple="true"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder="Type to search"
            track-by="id"
            label="code"
            :disabled="false"
            @search-change="onSrNumberSearch"
            @input="onSrNumberChange"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.code }}</span>
              </div>
            </template>
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>{{ props.option.code }}</span>
                <i
                  class="multiselect__tag-icon"
                  @click.prevent
                  @mousedown.prevent.stop="props.remove(props.option, $event)"
                />
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <!-- <div
        class="vx-col sm:w-2/3 w-full whitespace-no-wrap"
        v-if="currentTab <= 1"
      >
        <div class="flex flex-col gap-1">
          <span>GRR Type</span>
          <multiselect
            class="selectExample"
            v-model="selectedGRRType"
            :options="GrrTypeOptions"
            :multiple="false"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="3"
            placeholder="Type to search"
            track-by="id"
            :disabled="false"
            label="name"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.name }}</span>
              </div>
            </template>
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span class="option__title"> {{ props.option.name }}</span>
                <i
                  class="multiselect__tag-icon"
                  @click.prevent
                  @mousedown.prevent.stop="props.remove(props.option, $event)"
                />
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div
        class="vx-col sm:w-2/3 w-full whitespace-no-wrap"
        v-if="currentTab <= 1"
      >
        <div class="flex flex-col gap-1">
          <span>Territory</span>
          <territory-filter :full="true" :showTitle="false" />
        </div>
      </vs-tab>
      <vs-tab label="Paid">
        <div class="tab-text">
          <paid></paid>
        </div>
      </vs-tab>
      <vs-tab label="Reversed">
        <div class="tab-text">
          <reversed></reversed>
        </div>
      </div> -->

      <!-- <div
        class="vx-col sm:w-2/3 w-full whitespace-no-wrap"
        v-if="currentTab <= 1"
      >
        <territory-filter :full="true" :showTitle="false"/>
      </div> -->
      <vs-tabs v-model="currentTab">
        <vs-tab label="Supplier Return">
          <supplier-return
            :supplierCodes="selectedSupplierCodes"
            :srNumbers="selectedSRNumbers"
            :showData="showData"
            :supplierCodeOptions="suplierCodeOptions"
            :SrNumberOptions="SrNumberOptions"
            :grrDate="grrDate"
            :grrType="selectedGRRType"
          />
        </vs-tab>
        <vs-tab label="Draft">
          <draft
            :supplierCodes="selectedSupplierCodes"
            :supplierCodeOptions="suplierCodeOptions"
            :showData="showData"
            :grrDate="grrDate"
            :grrType="selectedGRRType"
            :srNumbers="selectedSRNumbers"
          />
        </vs-tab>
        <vs-tab label="Open With Ref">
          <open-with></open-with>
        </vs-tab>
        <vs-tab label="Open Without Ref">
          <open-without></open-without>
        </vs-tab>
        <vs-tab label="Pending">
          <div class="tab-text">
            <pending></pending>
          </div>
        </vs-tab>
        <vs-tab label="Reject">
          <div class="tab-text">
            <reject></reject>
          </div>
        </vs-tab>
        <vs-tab label="Approved">
          <div class="tab-text">
            <approved></approved>
          </div>
        </vs-tab>
        <vs-tab label="Applied">
          <div class="tab-text">
            <applied></applied>
          </div>
        </vs-tab>
        <!-- <vs-tab label="Paid">
          <div class="tab-text">
            <paid></paid>
          </div>
        </vs-tab> -->
        <vs-tab label="Paid">
          <div class="tab-text">
            <NewPaid></NewPaid>
          </div>
        </vs-tab>
        <vs-tab label="Reversed">
          <div class="tab-text">
            <reversed></reversed>
          </div>
        </vs-tab>
        <vs-tab label="Waiting GRR Reverse">
          <WaitingGrr></WaitingGrr>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import OpenWith from "./open_with_ref";
import OpenWithout from "./open_without_ref";
import Pending from "./pending";
import Reject from "./reject";
import Approved from "./approved";
import Applied from "./applied";
import NewPaid from "./paidV2";
import Paid from "./paid";
import Reversed from "./reversed";
import SupplierReturn from "./supplier_return/index.vue";
import Draft from "./draft/index.vue";
import WaitingGrr from "./waiting_grr_reverse/index.vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
import territoryFilter from "../../../../components/operatingUnitFilter/territoryFilter.vue";
import Territory from "../../../../components/master/Territory.vue";
import debounce from "debounce";
export default {
  components: {
    OpenWith,
    OpenWithout,
    Pending,
    Approved,
    Applied,
    Paid,
    Reject,
    Reversed,
    WaitingGrr,
    NewPaid,
    "supplier-return": SupplierReturn,
    draft: Draft,
    "date-range-picker": DateRangePicker,
    "territory-filter": territoryFilter,
    Territory,
  },
  mounted() {
    if (this.$route.params.tab) {
      console.log("params", this.$route.params.tab);
      this.currentTab = this.$route.query.tab;
    }
    this.getFilters();
    this.selectedSRNumbers = [this.SrNumberAllOption];
    this.selectedSupplierCodes = [this.suplierCodeAllOption];
  },
  data() {
    return {
      currentTab: 0,
      suplierCodeOptions: [],
      selectedSupplierCodes: [],
      selectedSRNumbers: [],
      SrNumberOptions: [],
      GrrTypeOptions: [
        { id: 0, name: "All" },
        { id: 1, name: "With Ref" },
        { id: 2, name: "Without Ref" },
      ],
      selectedGRRType: { id: 0, name: "All" },
      params: {
        pr_status: 11,
        sr_status: 8,
      },
      showData: false,
      suplierCodeAllOption: {
        id: 0,
        supplier_code: "All",
        supplier_name: "All",
      },
      SrNumberAllOption: {
        id: 0,
        code: "All",
      },
      grrDate: {
        startDate: null,
        endDate: null,
      },
      debounceFunction: null,
    };
  },
  methods: {
    getSupplierCodes(search = "") {
      const params = {
        ...this.params,
        is_supplier_code: true,
        search,
      };
      return this.$http.get("/api/v1/debit-note/supplier-return/filter", {
        params,
      });
    },
    getDNSupplierCodes(search = "") {
      const params = {
        search: search ? search : undefined,
      };

      return this.$http.get("/api/v1/debit-note/return/filter/supplier-code", {
        params,
      });
    },

    getDNSrCodes(search = "") {
      const params = {
        search: search ? search : undefined,
      };

      return this.$http.get(
        "/api/v1/debit-note/return/filter/supplier-return-code",
        {
          params,
        }
      );
    },

    getSupplirReturnNumbers(search = "") {
      const params = {
        ...this.params,
        is_supplier_code: false,
        search,
      };
      return this.$http.get("/api/v1/debit-note/supplier-return/filter", {
        params,
      });
    },
    async fetchSupplierCodes(search = "") {
      try {
        let res = await this.getSupplierCodes(search);
        if (res.code < 299) {
          this.suplierCodeOptions = res.data ? res.data : [];
        } else {
          throw new Error(res.message);
        }
      } catch (e) {
        throw new Error(e.message);
      }
    },
    async fetchSupplierReturnNumbers(search = "") {
      try {
        let res = await this.getSupplirReturnNumbers(search);
        if (res.code < 299) {
          this.SrNumberOptions = res.data ? res.data : [];
        } else {
          throw new Error(res.message);
        }
      } catch (e) {
        throw new Error(e.message);
      }
    },
    async fetchDNSupplierCodes(search = "") {
      try {
        let res = await this.getDNSupplierCodes(search);
        if (res.code < 299) {
          this.suplierCodeOptions = res.data ? res.data : [];
        } else {
          throw new Error(res.message);
        }
      } catch (e) {
        throw new Error(e.message);
      }
    },

    async fetchDNSrCodes(search = "") {
      try {
        let res = await this.getDNSrCodes(search);
        if (res.code < 299) {
          this.SrNumberOptions = res.data ? res.data : [];
        } else {
          throw new Error(res.message);
        }
      } catch (e) {
        throw new Error(e.message);
      }
    },
    onSupplierCodeChange(value) {
      if (value.length === 0) {
        this.selectedSupplierCodes = [this.suplierCodeAllOption];
        return;
      }

      if (value[0].id === 0) {
        value.shift();
        this.selectedSupplierCodes = value;
      } else if (value.some((sc) => sc.id === 0)) {
        this.selectedSupplierCodes = [this.suplierCodeAllOption];
      } else {
        this.selectedSupplierCodes = value;
      }
    },
    onSrNumberChange(value) {
      if (value.length === 0) {
        this.selectedSRNumbers = [this.SrNumberAllOption];
        return;
      }

      if (value[0].id === 0) {
        value.shift();
        this.selectedSRNumbers = value;
      } else if (value.some((sr) => sr.id === 0)) {
        this.selectedSRNumbers = [this.SrNumberAllOption];
      } else {
        this.selectedSRNumbers = value;
      }
    },
    async onSupplierCodeSearch(search) {
      try {
        // if (search.length < 2 && search.length !== 0) {
        //   return;
        // }
        if (this.debounceFunction) {
          this.debounceFunction.clear();
        }

        this.debounceFunction = debounce(async () => {
          this.$vs.loading();
          if (this.currentTab === 0) {
            await this.fetchSupplierCodes(search);
          } else {
            await this.fetchDNSupplierCodes(search);
          }
          this.$vs.loading.close();
        }, 500);

        this.debounceFunction();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        this.$vs.notify({
          title: "Error",
          text: e.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
      }
    },
    async onSrNumberSearch(search) {
      try {
        if (search.length < 3 && search.length !== 0) {
          return;
        }

        if (this.debounceFunction) {
          this.debounceFunction.clear();
        }

        this.debounceFunction = debounce(async () => {
          this.$vs.loading();
          if (this.currentTab === 0) {
            await this.fetchSupplierReturnNumbers(search);
          } else {
            await this.fetchDNSrCodes(search);
          }
          this.$vs.loading.close();
        }, 500);

        this.debounceFunction();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        this.$vs.notify({
          title: "Error",
          text: e.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
      }
    },
    async getFilters(currentTab = 0) {
      try {
        this.showData = false;
        this.$vs.loading();
        if (currentTab === 0) {
          await this.fetchSupplierCodes();
          await this.fetchSupplierReturnNumbers();
        } else {
          await this.fetchDNSupplierCodes();
          await this.fetchDNSrCodes();
        }
        this.$vs.loading.close();
        this.showData = true;
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        this.$vs.notify({
          title: "Error",
          text: e.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
      }
    },
    queryPrincipal({ supplier_code, supplier_name }) {
      if (supplier_code === "All" || supplier_name === "All") {
        return "All";
      }
      return `${supplier_code} - ${supplier_name}`;
    },
    clearGrrDate() {
      this.grrDate = {
        startDate: null,
        endDate: null,
      };
    },
  },
  watch: {
    currentTab() {
      if(this.currentTab <= 1)
      this.getFilters(this.currentTab);
      this.selectedSRNumbers = [this.SrNumberAllOption];
      this.selectedSupplierCodes = [this.suplierCodeAllOption];
    },
  },
};
</script>
